<template>
    <div class="apply">
        <div class="img_left">
            <img style="width: 252px;height: 680px;" src="../assets/images/bossapply/Group 2165.png" alt="">
        </div>
        <section class="apply_right">
            <div class="type-img">
                <img src="../assets/images/bossapply/Subtract.png" alt="">
            </div>
            <div class="type">
            </div>
            <b-row>
                <div class="apply-title1">
                    <p style="">申请试用</p>
                </div>
                <div class="apply-title2">
                    <p><span style="font-weight: 00;">300+</span>大型企业的共同选择</p>
                </div>
            </b-row>
            <b-row>
                <div class="apply-title3">
                    <p>专业服务团队,为您提供最合适的解决方案</p>
                </div>
            </b-row>
            <div class="apply-body">
                <b-row class="apply_cms">
                    <b-col md="5">
                        <label><span style="color:red;">* </span>称呼</label>
                        <el-input placeholder="" v-model="bossContact.name" clearable></el-input>
                    </b-col>
                    <b-col md="5">
                        <label><span style="color:red;">* </span>企业名称</label>
                        <el-input placeholder="" v-model="bossContact.ename" clearable></el-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="5">
                        <label><span style="color:red;">* </span>联系电话</label>
                        <el-input placeholder="" v-model="bossContact.telephone" clearable
                            style="margin-top: 0px;"></el-input>
                    </b-col>
                    <b-col md="5">
                        <label><span style="color:red;">* </span>企业邮箱</label>
                        <el-input type="email" placeholder="" v-model="bossContact.email" clearable></el-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="10">
                        <label><span style="color:red;">* </span>需求</label>
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="bossContact.reply">
                        </el-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4">
                        <el-button @click="submit()"
                            style="width: 160px;height: 48px;background: linear-gradient(90deg, #0139D0 0%, #00A3FF 100%);border-radius: 6px 6px 6px 6px;opacity: 1;margin-top: 40px;right:-386px;position: absolute;color:#ffffff;"><b>提交</b></el-button>
                    </b-col>
                </b-row>
            </div>
        </section>
        <b-container fluid="xl">
            <b-row>
                <b-col md="12">
                    <div class="zoomlaFoot">
                      版权所有@ 2024 广东壹公里数智有限公司 <a href="https://beian.miit.gov.cn" target="_blank" style="color:#8085ad;">粤ICP备19057806号</a>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "apply",
    data() {
        return {
            bossContact: {
                name: '',
                ename: '',
                telephone: '',
                email: '',
                reply: ''
            },
        };
    },
    methods: {
        async submit() {
            var ref = this;
            if (ref.Regex.isEmpty(ref.bossContact.name)) {
                ref.util.alert("姓名不能为空");
                return false;
            }
            if (!ref.Regex.isMobilePhone(ref.bossContact.telephone)) {
                ref.util.alert("手机号格式不正确 ");
                return false;
            }
            await this.$http.post("/contact/add", ref.bossContact).then((e) => {
                if (e.status == 200) {
                    alert("我们会第一时间联系你");
                }
            });
            this.bossContact = {
              name: '',
              ename: '',
              telephone: '',
              email: '',
              reply: ''
            }
        },
    },
}
</script>



<style scoped lang="scss">
.apply {
    position: relative;
    margin: auto;
    margin-top: 100px;
    width: 1080px;
    height: 680px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px 0px rgba(44, 81, 154, 0.16);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-bottom: 200px;
}

.img_left {
    width: 182px;
    height: 680px;
    //border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    float: left;
    position: relative;
}

.apply_right {
    margin-top: auto;
    width: 898px;
    height: 680px;
    float: right;
    background: #FFFFFF;
    position: relative;
    text-align: center;
}

.apply-body {
    text-align: left;
    font-size: 12;
    line-height: 3;
    top: 220px;
    width: 768px;
    height: 502px;
    margin: auto;
    left: 110px;
    position: absolute;
}

.apply-title1 {
    position: absolute;
    float: left;
    width: 192px;
    height: 38px;
    font-size: 38px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #1B212E;
    line-height: 48px;
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    left: 90px;
    top: 60px;
}

.apply-title2 {
    position: absolute;
    width: 560px;
    height: 38px;
    font-size: 38px;
    font-family: SF Pro Display-Semibold, SF Pro Display;
    font-weight: 500;
    color: #1B212E;
    line-height: 48px;
    -webkit-background-clip: text;
    right: 90px;
    top: 60px;
    //     -webkit-text-fill-color: transparent;
}

.apply-title3 {
    width: 440px;
    height: 24px;
    font-size: 24px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 300;
    color: #3D485D;
    line-height: 24px;
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    position: absolute;
    left: 110px;
    top: 136px;
}

.type-img {
    float: left;
    width: 36px;
    height: 36px;
    left: 80px;
    top: 42px;
    // background: linear-gradient(90deg, #0139D0 0%, #00A3FF 100%);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    position: relative;
}

.type {
    float: left;
    left: 270px;
    top: 86px;
    width: 100px;
    height: 24px;
    background: #C3DAFF;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    position: relative;
}

.zoomlaFoot {
    text-align: center;
    height: 32px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #8085ad;
    line-height: 32px;
    position: fixed;
    bottom: -120px;
    position: relative;
}
</style> 